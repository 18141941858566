<template>
  <v-card style="padding: 5px;">
    <v-card-title class="d-flex justify-center py-6">
      Detalhes {{ document.type }}
    </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        @submit.prevent="sendData()"
      >
        <div
          v-if="document.type === 'CIR'"
          class="separator-title"
        >
          <p class="px-2">
            Informações do emissor
          </p>
        </div>
        <div
          v-if="document.type === 'CIR'"
          class="emissorGrid"
        >
          <v-autocomplete
            v-model="document.users_approve_id"
            :items="leaderList"
            :rules="[rules.required]"
            item-text="name"
            item-value="id"
            label="Nome"
            outlined
            dense
          />
          <v-text-field
            v-model="document.users_approve.occupation_sector.sector.name"
            label="Setor"
            readonly
            outlined
            dense
          />
          <v-text-field
            v-model="document.users_approve.company.fantasy_name"
            label="Loja"
            readonly
            outlined
            dense
          />
          <v-text-field
            v-model="documentDate"
            label="Registro no sistema"
            readonly
            outlined
            dense
          />
        </div>
        <div class="separator-title">
          <p class="px-2">
            Dados do documento
          </p>
        </div>

        <div class="mt-6">
          <div
            class="d-flex align-center"
            style="gap: 20px;"
          >
            <v-text-field
              v-model="document.code"
              style="width: 10%;"
              :label="`Código ${document.type}`"
              outlined
              dense
            />

            <v-text-field
              v-if="document.type !== 'CIR'"
              v-model="document.name"
              style="width: 48%;"
              label="Nome"
              outlined
              dense
            />
            <v-text-field
              v-if="document.type === 'CIR'"
              v-model="document.name"
              :rules="[rules.required]"
              style="width: 45%;"
              label="Assunto"
              outlined
              dense
            />

            <v-autocomplete
              v-if="document.type !== 'CIR'"
              v-model="document.companies_id"
              :items="companies"
              item-text="name"
              item-value="id"
              label="Empresa"
              no-data-text="Buscando..."
              :loading="!companies.length"
              outlined
              dense
            />
          </div>

          <div
            class="d-flex"
            style="gap: 20px;"
          >
            <v-autocomplete
              v-if="document.type !== 'CIR'"
              v-model="document.occupations_sectors_id"
              label="Função principal"
              :rules="[rules.required]"
              :items="occupations"
              item-text="name"
              item-value="id"
              no-data-text="Buscando..."
              :loading="!occupations.length"
              outlined
              dense
            />

            <v-file-input
              v-model="files"
              style="min-width: 30%; max-width: fit-content;"
              label="Anexar Documento"
              :append-icon="icons.mdiPaperclip"
              :prepend-icon="null"
              outlined
              dense
            />
          </div>
        </div>

        <div
          class="d-flex"
          style="gap: 20px;"
        >
          <v-autocomplete
            v-if="document.type !== 'CIR'"
            v-model="document.process_id"
            :items="process"
            item-text="description"
            item-value="id"
            label="Processo"
            no-data-text="Buscando..."
            :loading="!process.length"
            style="width: 61%;"
            outlined
            dense
          />

          <v-text-field
            v-if="document.type !== 'CIR'"
            v-model="documentDate"
            label="Registro no sistema"
            readonly
            outlined
            dense
          />
        </div>

        <div
          v-if="document.type !== 'CIR'"
          class="separator-title"
        >
          <p class="px-2">
            Detalhes
          </p>
        </div>

        <div
          v-if="document.type !== 'CIR'"
          class="d-flex mt-6 mb-2"
          style="gap: 20px;"
        >
          <v-autocomplete
            v-model="document.users_revision_id"
            :items="employees"
            item-text="name"
            item-value="id"
            label="Revisor"
            outlined
            dense
            no-data-text="Dados não encontrados"
          />

          <v-autocomplete
            v-model="document.users_approve_id"
            :items="employees"
            item-text="name"
            item-value="id"
            label="Aprovador"
            outlined
            dense
            no-data-text="Dados não encontrados"
          />
        </div>

        <div class="separator-title">
          <p class="px-2">
            Atribuir funções
          </p>
        </div>

        <div class="mt-6">
          <div
            class="d-flex align-center"
            style="gap: 10px;"
          >
            <span class="info-text">Quais <strong>funções</strong> devem <strong>ver</strong> este documento?</span>

            <v-icon
              style="top: 2px;"
              size="25"
            >
              {{ icons.sgqDocumentAccountQuestion }}
            </v-icon>
          </div>

          <v-autocomplete
            v-model="document.occupation_registration"
            class="mt-4"
            style="min-width: 40%; max-width: fit-content;"
            label="Funções"
            :items="occupations"
            item-text="name"
            item-value="id"
            small-chips
            clearable
            multiple
            outlined
            dense
            return-object
            no-data-text="Buscando..."
            :loading="!occupations.length"
          />
        </div>

        <div class="d-flex justify-center">
          <v-btn
            color="info"
            type="submit"
            :loading="isLoadingSendData"
          >
            <div
              class="d-flex align-center"
              style="gap: 5px;"
            >
              <v-icon>{{ icons.sgqFileDocumentPlus }}</v-icon>

              <span>
                Atualizar
              </span>
            </div>
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { sgqDocumentAccountQuestion, sgqFileDocumentPlus } from '@/assets/images/svg/sgqDocument'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiAccountQuestion, mdiPaperclip } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'

export default {
  mixins: [formatters, messages],

  props: {
    rawDocument: {
      type: Object,
      default: () => ({
        code: '',
        name: '',
        type: '',
        users_approve_id: '',
        users_revision_id: '',
        process_id: '',
        occupations_id: '',
        occupations_sectors_id: '',
        occupation_registration: [],
        companies_id: '',
        created_at: '',
      }),
    },

    occupations: {
      type: Array,
      required: true,
    },

    process: {
      type: Array,
      required: true,
    },

    companies: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      files: null,
      employees: [],
      leaderList: [],
      isLoadingProcess: false,
      isLoadingSendData: false,

      occupation: [],

      icons: {
        mdiPaperclip,
        mdiAccountQuestion,
        sgqDocumentAccountQuestion,
        sgqFileDocumentPlus,
      },
    }
  },

  computed: {
    documentDate() {
      return this.dateFormat(this.document.created_at)
    },
  },

  created() {
    this.employees = localStorageSlim.get('listEmployees', { decrypt: true })
    this.leaderList = localStorageSlim.get('leaderList', { decrypt: true })
    this.document = this.formatDocumentData()
    console.log(this.document)
  },

  methods: {
    formatDocumentData() {
      const { occupation_registration: occupationRegistration } = this.rawDocument

      return {
        ...this.rawDocument,
        occupation_registration: occupationRegistration?.map(occupation => ({
          ...occupation,
          id: occupation.occupations_sectors_id,
        })),
      }
    },

    async sendData() {
      this.isLoadingSendData = true

      const body = {
        code: this.document.code,
        name: this.document.name,
        type: this.document.type,
        users_approve_id: this.document.users_approve_id,
        users_revision_id: this.document.users_revision_id,
        process_id: this.document.process_id,
        occupations_sectors_id: this.document.occupations_sectors_id,
        occupations_sectors: this.document.occupation_registration?.map(({ id }) => id),
        companies_id: this.document.companies_id,
      }

      try {
        await axiosIns.put(`api/v1/sgq/registration/update/${this.document.id}`, body)

        if (this.files) await this.sendFiles()

        this.showMessage({
          title: 'Sucesso',
          text: 'Documento atualizado com sucesso',
          color: 'success',
        })
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.updatedTable()
        this.closeModal()
        this.isLoadingSendData = false
      }
    },

    async sendFiles() {
      try {
        const formData = new FormData()

        formData.append('id', this.document.id)
        formData.append('files[]', this.files)

        await axiosIns.post('api/v1/sgq/registration/submit_files', formData)
      } catch (error) {
        this.showMessage({
          title: 'Erro',
          text: 'Erro ao salvar arquivos',
          color: 'error',
        })
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
.separator-title {
  border-bottom: 1px solid #CAC9CD;
  height: 1px;
  width: 100%;
  margin-bottom: 10px;

  p {
    position: relative;
    bottom: 10px;
    left: 40px;
    background: #312D4B;
    width: fit-content;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.info-text {
  strong {
    color: #EF70FF;
  }
}
.emissorGrid {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Define 2 colunas com tamanhos iguais */
  grid-template-rows: repeat(2, 1fr); /* Define 2 linhas com tamanhos iguais */
  gap: 10px;
}
</style>
