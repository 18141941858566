<template>
  <v-card style="padding: 5px;">
    <v-card-title class="d-flex justify-center py-6">
      Cadastro {{ documentType }}
    </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        @submit.prevent="sendData()"
      >
        <div
          v-if="documentType === 'CIR'"
          class="separator-title"
        >
          <p class="px-2">
            Informações do emissor
          </p>
        </div>

        <div
          v-if="documentType === 'CIR'"
          class="my-6"
        >
          <v-autocomplete
            v-model="approverId"
            :items="leaderList"
            :rules="[rules.required]"
            item-text="name"
            item-value="id"
            label="Emissor"
            outlined
            dense
          />
        </div>

        <div class="separator-title">
          <p class="px-2">
            Dados do documento
          </p>
        </div>

        <div class="mt-6">
          <div
            class="d-flex align-center"
            style="gap: 20px;"
          >
            <v-text-field
              v-model="documentCode"
              :rules="[rules.required]"
              style="width: 10%;"
              :label="`Código ${documentType}`"
              type="number"
              outlined
              dense
            />

            <v-text-field
              v-if="documentType !== 'CIR'"
              v-model="name"
              :rules="[rules.required]"
              style="width: 45%;"
              label="Nome"
              outlined
              dense
            />
            <v-text-field
              v-if="documentType === 'CIR'"
              v-model="name"
              :rules="[rules.required]"
              style="width: 45%;"
              label="Assunto"
              outlined
              dense
            />

            <v-text-field
              v-if="documentType !== 'CIR'"
              v-model="companieInfo.name"
              readonly
              item-text="name"
              item-value="id"
              label="Empresa"
              :loading="!companies.length"
              no-data-text="Buscando..."
              outlined
              dense
            />
          </div>

          <div
            class="d-flex"
            style="gap: 20px;"
          >
            <v-autocomplete
              v-if="documentType !== 'CIR'"
              v-model="mainOccupationId"
              style="width: 46%;"
              label="Função principal"
              :rules="[rules.required]"
              :items="occupations"
              item-text="name"
              item-value="id"
              :loading="!occupations.length"
              no-data-text="Buscando..."
              outlined
              dense
            />

            <v-file-input
              v-model="files"
              label="Anexar Documento"
              :rules="[(files => !!files || 'Este campo é obrigatório.')]"
              :prepend-inner-icon="icons.mdiPaperclip"
              :prepend-icon="null"
              outlined
              chips
              dense
            />
          </div>
        </div>

        <div
          class="d-flex"
          style="width: 69%; margin-bottom: 12px;"
        >
          <v-autocomplete
            v-if="documentType !== 'CIR'"
            v-model="processId"
            :items="process"
            :rules="[rules.required]"
            item-text="description"
            item-value="id"
            label="Processo"
            :loading="!process.length"
            no-data-text="Buscando..."
            outlined
            dense
          />
        </div>

        <div
          v-if="documentType !== 'CIR'"
          class="separator-title"
        >
          <p class="px-2">
            Detalhes
          </p>
        </div>

        <div
          v-if="documentType !== 'CIR'"
          class="d-flex mt-6 mb-2"
          style="width: 100%; gap: 20px;"
        >
          <v-autocomplete
            v-model="reviewerId"
            :items="employees"
            :rules="[rules.required]"
            item-text="name"
            item-value="id"
            label="Revisor"
            outlined
            dense
          />

          <v-autocomplete
            v-model="approverId"
            :items="employees"
            :rules="[rules.required]"
            label="Aprovador"
            item-text="name"
            item-value="id"
            outlined
            dense
          />
        </div>

        <div class="separator-title">
          <p class="px-2">
            Atribuir funções
          </p>
        </div>

        <div class="mt-6">
          <div
            class="d-flex align-center"
            style="gap: 10px;"
          >
            <span class="info-text">Quais <strong>funções</strong> devem <strong>ver</strong> este documento?</span>

            <v-icon
              style="top: 2px;"
              size="25"
            >
              {{ icons.sgqDocumentAccountQuestion }}
            </v-icon>
          </div>

          <v-autocomplete
            v-model="idsOfOccupations"
            class="mt-4"
            style="min-width: 40%; max-width: fit-content;"
            label="Funções"
            :items="occupations"
            :rules="[list => list.length || 'Campo obrigatório']"
            item-text="name"
            item-value="id"
            multiple
            clearable
            outlined
            chips
            dense
            no-data-text="Buscando..."
            :loading="!occupations.length"
          />
        </div>

        <div class="d-flex justify-center">
          <v-btn
            color="info"
            type="submit"
            :loading="isLoadingSendData"
          >
            <div
              class="d-flex align-center"
              style="gap: 5px;"
            >
              <v-icon>{{ icons.sgqFileDocumentPlus }}</v-icon>

              <span>
                Cadastrar
              </span>
            </div>
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { sgqDocumentAccountQuestion, sgqFileDocumentPlus } from '@/assets/images/svg/sgqDocument'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiAccountQuestion, mdiPaperclip } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'

export default {
  mixins: [formatters, messages],

  props: {
    occupations: {
      type: Array,
      required: true,
    },

    process: {
      type: Array,
      required: true,
    },

    companies: {
      type: Array,
      required: true,
      default: () => [],
    },

    documentType: {
      type: String,
      default: '',
    },

    companieInfo: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      name: '',
      companyId: '',
      processId: '',
      approverId: '',
      reviewerId: '',
      documentCode: '',
      mainOccupationId: '',

      files: null,
      employees: [],
      idsOfOccupations: [],

      isLoadingSendData: false,

      icons: {
        mdiPaperclip,
        mdiAccountQuestion,
        sgqDocumentAccountQuestion,
        sgqFileDocumentPlus,
      },
    }
  },

  watch: {
    name() {
      this.name = this.name.toUpperCase()
    },
  },

  created() {
    this.employees = localStorageSlim.get('listEmployees', { decrypt: true })
    this.leaderList = localStorageSlim.get('leaderList', { decrypt: true })
  },

  methods: {
    async sendData() {
      const formData = new FormData()
      const formIsValid = this.$refs.form.validate()

      if (!formIsValid) {
        await this.showMessage({
          icon: 'error',
          title: 'Campos obrigatórios',
          text: 'Preencha todos os campos',
        })

        return
      }

      this.isLoadingSendData = true

      formData.append('code', this.documentCode)
      formData.append('name', this.name)
      formData.append('type', this.documentType)
      formData.append('users_approve_id', this.approverId)
      formData.append('users_revision_id', this.reviewerId)
      formData.append('process_id', this.processId)
      formData.append('occupations_sectors_id', this.mainOccupationId)
      formData.append('files[]', this.files)
      formData.append('companies_id', this.companieInfo.id)

      this.idsOfOccupations.forEach(occupation => {
        formData.append('occupations_sectors[]', occupation)
      })

      try {
        await axiosIns.post('api/v1/sgq/registration/registration', formData)
        await this.showMessage({
          title: 'Sucesso',
          text: 'Documento criado com sucesso',
          icon: 'success',
        })
        this.updatedTable()
      } catch (error) {
        console.error(error)
        this.showErrorMessage(error)
      } finally {
        this.resetForm()
        this.closeModal()
        this.isLoadingSendData = false
      }
    },

    resetForm() {
      this.$refs.form.reset()
    },
  },
}
</script>

<style lang="scss">
.separator-title {
  border-bottom: 1px solid #CAC9CD;
  height: 1px;
  width: 100%;
  margin-bottom: 10px;

  p {
    position: relative;
    bottom: 10px;
    left: 40px;
    background: #312D4B;
    width: fit-content;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.info-text {
  strong {
    color: #EF70FF;
  }
}
</style>
